import {Device} from 'src/app/app-shared-elements/_interfaces/Device';
import {Group} from 'src/app/app-shared-elements/_interfaces/Group';
import {Params} from 'src/app/app-shared-elements/_interfaces/params.interface';

export class SetGroupDashboardParams {
    static readonly type = '[GroupDashboard] SetGroupDashboardParams';

    constructor(public params: Params) {
    }
}

export class InitGroupDashboard {
    static readonly type = '[GroupDashboard] InitGroupDashboard';

    constructor(public groups: Group[], public devices: Device[]) {
    }
}

