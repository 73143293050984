import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {GroupOfDashboard} from 'src/app/app-shared-elements/_interfaces/Group';
import {Params, ParamsFilterForClient} from 'src/app/app-shared-elements/_interfaces/params.interface';
import {compareFilters} from 'src/app/app-shared-elements/_services/table.service';
import {groupsDashboardFilters} from '../../_data/groups-dashboard-params';
import {InitGroupDashboard, SetGroupDashboardParams} from '../actions/groups-dashboard.actions';

export interface GroupsDashboardStateModel {
    groupsDashboard: GroupOfDashboard[];
    params: Params;
    isEmptyGroups: boolean;
}

const GROUPS_DASHBOARD_STATE_TOKEN = new StateToken<GroupsDashboardStateModel>('groupDashboardState');

const initialFilters: ParamsFilterForClient[] = JSON.parse(localStorage.getItem('checkboxDashboardFilterGroups')) && compareFilters(groupsDashboardFilters, JSON.parse(localStorage.getItem('checkboxDashboardFilterGroups')))
    ? JSON.parse(localStorage.getItem('checkboxDashboardFilterGroups'))
    : groupsDashboardFilters;

const initialParams: Params = {
    sorted: null,
    pagination: null,
    filter: initialFilters
};

@State<GroupsDashboardStateModel>({
    name: GROUPS_DASHBOARD_STATE_TOKEN,
    defaults: {
        groupsDashboard: [],
        params: initialParams,
        isEmptyGroups: false
    }
})
@Injectable()
export class GroupDashboardState {
    constructor() {

    }

    @Selector()
    static getDashboardGroups(state: GroupsDashboardStateModel): GroupOfDashboard[] {
        return state.groupsDashboard;
    }

    @Selector()
    static getParams(state: GroupsDashboardStateModel): Params {
        return state.params;
    }


    @Action(SetGroupDashboardParams)
    setGroupDashboardParams(ctx: StateContext<GroupsDashboardStateModel>, payload: SetGroupDashboardParams): void {
        const state = ctx.getState();
        localStorage.setItem('checkboxDashboardFilterGroups', JSON.stringify(payload.params.filter));
        ctx.setState({
            ...state,
            params: {...payload.params}
        });
    }

    @Action(InitGroupDashboard)
    initGroupDashboard(ctx: StateContext<GroupsDashboardStateModel>, payload: InitGroupDashboard): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            groupsDashboard: payload.groups.map((g, index) => {
                const currentDevice = payload.devices.find(d => d.id === g.deviceId);

                if (!currentDevice) {
                    return;
                }

                return {
                    ...g,
                    type: currentDevice.type,
                    status: currentDevice.status,
                    isConnect: currentDevice.isConnect
                };
            }).filter(g => !!g)
        });
    }

}
