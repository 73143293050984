import { DeviceTypeEnum } from 'src/app/app-shared-elements/_enums/device-type.enum';
import { ParamsFilterForClient, ParamsFilterTypeEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { AlarmTypeEnum } from 'src/app/events/_enums/alarm.enum';

export const groupsDashboardFilters: ParamsFilterForClient[] = [
    {
        title: 'groups.filters.registrators',
        property: 'type',
        value: true,
        type: ParamsFilterTypeEnum.BOOLEAN,
        isRadio: true,
        relationTrue: [
            {
                property: 'type',
                value: DeviceTypeEnum.registrator,
                type: ParamsFilterTypeEnum.TEXT,
            },
        ],
    },
    {
        title: 'groups.filters.sensors',
        property: 'type',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        isRadio: true,
        relationTrue: [
            {
                property: 'type',
                value: DeviceTypeEnum.sensor,
                type: ParamsFilterTypeEnum.TEXT,
            },
        ],
    },
    {
        title: 'groups.filters.coordinators',
        property: 'type',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        isRadio: true,
        relationTrue: [
            {
                property: 'type',
                value: DeviceTypeEnum.coordinator,
                type: ParamsFilterTypeEnum.TEXT,
            },
        ],
    },
    {
        title: 'groups.filters.dataloggers',
        property: 'type',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        isRadio: true,
        relationTrue: [
            {
                property: 'type',
                value: DeviceTypeEnum.datalogger,
                type: ParamsFilterTypeEnum.TEXT,
            },
        ],
    },
    {
        title: 'groups.filters.online',
        property: 'isConnect',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
    },
    {
        title: 'groups.filters.ok',
        property: 'status',
        value: true,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'status',
                value: AlarmTypeEnum.ok,
                type: ParamsFilterTypeEnum.TEXT,
            },
        ],
    },
    {
        title: 'groups.filters.alarm',
        property: 'status',
        value: true,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'status',
                value: AlarmTypeEnum.alarm,
                type: ParamsFilterTypeEnum.TEXT,
            },
        ],
    },
    {
        title: 'groups.filters.attention',
        property: 'status',
        value: true,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'status',
                value: AlarmTypeEnum.attention,
                type: ParamsFilterTypeEnum.TEXT,
            },
        ],
    },
];
